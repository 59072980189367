/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-_abkhazia {
  background-image: url(./sprite.png);
  background-position: 0px -42px;
  width: 32px;
  height: 32px;
}
.icon-_basque-country {
  background-image: url(./sprite.png);
  background-position: -32px -42px;
  width: 32px;
  height: 32px;
}
.icon-_british-antarctic-territory {
  background-image: url(./sprite.png);
  background-position: -80px 0px;
  width: 32px;
  height: 32px;
}
.icon-_commonwealth {
  background-image: url(./sprite.png);
  background-position: -80px -32px;
  width: 32px;
  height: 32px;
}
.icon-_england {
  background-image: url(./sprite.png);
  background-position: 0px -74px;
  width: 32px;
  height: 32px;
}
.icon-_gosquared {
  background-image: url(./sprite.png);
  background-position: -32px -74px;
  width: 32px;
  height: 32px;
}
.icon-_kosovo {
  background-image: url(./sprite.png);
  background-position: -64px -74px;
  width: 32px;
  height: 32px;
}
.icon-_mars {
  background-image: url(./sprite.png);
  background-position: -112px 0px;
  width: 32px;
  height: 32px;
}
.icon-_nagorno-karabakh {
  background-image: url(./sprite.png);
  background-position: -112px -32px;
  width: 32px;
  height: 32px;
}
.icon-_nato {
  background-image: url(./sprite.png);
  background-position: -112px -64px;
  width: 32px;
  height: 32px;
}
.icon-_northern-cyprus {
  background-image: url(./sprite.png);
  background-position: 0px -106px;
  width: 32px;
  height: 32px;
}
.icon-_olympics {
  background-image: url(./sprite.png);
  background-position: -32px -106px;
  width: 32px;
  height: 32px;
}
.icon-_red-cross {
  background-image: url(./sprite.png);
  background-position: -64px -106px;
  width: 32px;
  height: 32px;
}
.icon-_scotland {
  background-image: url(./sprite.png);
  background-position: -96px -106px;
  width: 32px;
  height: 32px;
}
.icon-_somaliland {
  background-image: url(./sprite.png);
  background-position: -144px 0px;
  width: 32px;
  height: 32px;
}
.icon-_south-ossetia {
  background-image: url(./sprite.png);
  background-position: -144px -32px;
  width: 32px;
  height: 32px;
}
.icon-_united-nations {
  background-image: url(./sprite.png);
  background-position: -144px -64px;
  width: 32px;
  height: 32px;
}
.icon-_unknown {
  background-image: url(./sprite.png);
  background-position: -144px -96px;
  width: 32px;
  height: 32px;
}
.icon-_wales {
  background-image: url(./sprite.png);
  background-position: 0px -138px;
  width: 32px;
  height: 32px;
}
.icon-AD {
  background-image: url(./sprite.png);
  background-position: -32px -138px;
  width: 32px;
  height: 32px;
}
.icon-AE {
  background-image: url(./sprite.png);
  background-position: -64px -138px;
  width: 32px;
  height: 32px;
}
.icon-AF {
  background-image: url(./sprite.png);
  background-position: -96px -138px;
  width: 32px;
  height: 32px;
}
.icon-AG {
  background-image: url(./sprite.png);
  background-position: -128px -138px;
  width: 32px;
  height: 32px;
}
.icon-AI {
  background-image: url(./sprite.png);
  background-position: -176px 0px;
  width: 32px;
  height: 32px;
}
.icon-AL {
  background-image: url(./sprite.png);
  background-position: -176px -32px;
  width: 32px;
  height: 32px;
}
.icon-AM {
  background-image: url(./sprite.png);
  background-position: -176px -64px;
  width: 32px;
  height: 32px;
}
.icon-AN {
  background-image: url(./sprite.png);
  background-position: -176px -96px;
  width: 32px;
  height: 32px;
}
.icon-AO {
  background-image: url(./sprite.png);
  background-position: -176px -128px;
  width: 32px;
  height: 32px;
}
.icon-AQ {
  background-image: url(./sprite.png);
  background-position: 0px -170px;
  width: 32px;
  height: 32px;
}
.icon-AR {
  background-image: url(./sprite.png);
  background-position: -32px -170px;
  width: 32px;
  height: 32px;
}
.icon-AS {
  background-image: url(./sprite.png);
  background-position: -64px -170px;
  width: 32px;
  height: 32px;
}
.icon-AT {
  background-image: url(./sprite.png);
  background-position: -96px -170px;
  width: 32px;
  height: 32px;
}
.icon-AU {
  background-image: url(./sprite.png);
  background-position: -128px -170px;
  width: 32px;
  height: 32px;
}
.icon-AW {
  background-image: url(./sprite.png);
  background-position: -160px -170px;
  width: 32px;
  height: 32px;
}
.icon-AX {
  background-image: url(./sprite.png);
  background-position: -208px 0px;
  width: 32px;
  height: 32px;
}
.icon-AZ {
  background-image: url(./sprite.png);
  background-position: -208px -32px;
  width: 32px;
  height: 32px;
}
.icon-BA {
  background-image: url(./sprite.png);
  background-position: -208px -64px;
  width: 32px;
  height: 32px;
}
.icon-BB {
  background-image: url(./sprite.png);
  background-position: -208px -96px;
  width: 32px;
  height: 32px;
}
.icon-BD {
  background-image: url(./sprite.png);
  background-position: -208px -128px;
  width: 32px;
  height: 32px;
}
.icon-BE {
  background-image: url(./sprite.png);
  background-position: -208px -160px;
  width: 32px;
  height: 32px;
}
.icon-BF {
  background-image: url(./sprite.png);
  background-position: 0px -202px;
  width: 32px;
  height: 32px;
}
.icon-BG {
  background-image: url(./sprite.png);
  background-position: -32px -202px;
  width: 32px;
  height: 32px;
}
.icon-BH {
  background-image: url(./sprite.png);
  background-position: -64px -202px;
  width: 32px;
  height: 32px;
}
.icon-BI {
  background-image: url(./sprite.png);
  background-position: -96px -202px;
  width: 32px;
  height: 32px;
}
.icon-BJ {
  background-image: url(./sprite.png);
  background-position: -128px -202px;
  width: 32px;
  height: 32px;
}
.icon-BL {
  background-image: url(./sprite.png);
  background-position: -160px -202px;
  width: 32px;
  height: 32px;
}
.icon-BM {
  background-image: url(./sprite.png);
  background-position: -192px -202px;
  width: 32px;
  height: 32px;
}
.icon-BN {
  background-image: url(./sprite.png);
  background-position: -240px 0px;
  width: 32px;
  height: 32px;
}
.icon-BO {
  background-image: url(./sprite.png);
  background-position: -240px -32px;
  width: 32px;
  height: 32px;
}
.icon-BR {
  background-image: url(./sprite.png);
  background-position: -240px -64px;
  width: 32px;
  height: 32px;
}
.icon-BS {
  background-image: url(./sprite.png);
  background-position: -240px -96px;
  width: 32px;
  height: 32px;
}
.icon-BT {
  background-image: url(./sprite.png);
  background-position: -240px -128px;
  width: 32px;
  height: 32px;
}
.icon-BW {
  background-image: url(./sprite.png);
  background-position: -240px -160px;
  width: 32px;
  height: 32px;
}
.icon-BY {
  background-image: url(./sprite.png);
  background-position: -240px -192px;
  width: 32px;
  height: 32px;
}
.icon-BZ {
  background-image: url(./sprite.png);
  background-position: 0px -234px;
  width: 32px;
  height: 32px;
}
.icon-CA {
  background-image: url(./sprite.png);
  background-position: -32px -234px;
  width: 32px;
  height: 32px;
}
.icon-CC {
  background-image: url(./sprite.png);
  background-position: -64px -234px;
  width: 32px;
  height: 32px;
}
.icon-CD {
  background-image: url(./sprite.png);
  background-position: -96px -234px;
  width: 32px;
  height: 32px;
}
.icon-CF {
  background-image: url(./sprite.png);
  background-position: -128px -234px;
  width: 32px;
  height: 32px;
}
.icon-CG {
  background-image: url(./sprite.png);
  background-position: -160px -234px;
  width: 32px;
  height: 32px;
}
.icon-CH {
  background-image: url(./sprite.png);
  background-position: -192px -234px;
  width: 32px;
  height: 32px;
}
.icon-CI {
  background-image: url(./sprite.png);
  background-position: -224px -234px;
  width: 32px;
  height: 32px;
}
.icon-CK {
  background-image: url(./sprite.png);
  background-position: -272px 0px;
  width: 32px;
  height: 32px;
}
.icon-CL {
  background-image: url(./sprite.png);
  background-position: -272px -32px;
  width: 32px;
  height: 32px;
}
.icon-CM {
  background-image: url(./sprite.png);
  background-position: -272px -64px;
  width: 32px;
  height: 32px;
}
.icon-CN {
  background-image: url(./sprite.png);
  background-position: -272px -96px;
  width: 32px;
  height: 32px;
}
.icon-CO {
  background-image: url(./sprite.png);
  background-position: -272px -128px;
  width: 32px;
  height: 32px;
}
.icon-CR {
  background-image: url(./sprite.png);
  background-position: -272px -160px;
  width: 32px;
  height: 32px;
}
.icon-CU {
  background-image: url(./sprite.png);
  background-position: -272px -192px;
  width: 32px;
  height: 32px;
}
.icon-CV {
  background-image: url(./sprite.png);
  background-position: -272px -224px;
  width: 32px;
  height: 32px;
}
.icon-CW {
  background-image: url(./sprite.png);
  background-position: 0px -266px;
  width: 32px;
  height: 32px;
}
.icon-CX {
  background-image: url(./sprite.png);
  background-position: -32px -266px;
  width: 32px;
  height: 32px;
}
.icon-CY {
  background-image: url(./sprite.png);
  background-position: -64px -266px;
  width: 32px;
  height: 32px;
}
.icon-CZ {
  background-image: url(./sprite.png);
  background-position: -96px -266px;
  width: 32px;
  height: 32px;
}
.icon-DE {
  background-image: url(./sprite.png);
  background-position: -128px -266px;
  width: 32px;
  height: 32px;
}
.icon-DJ {
  background-image: url(./sprite.png);
  background-position: -160px -266px;
  width: 32px;
  height: 32px;
}
.icon-DK {
  background-image: url(./sprite.png);
  background-position: -192px -266px;
  width: 32px;
  height: 32px;
}
.icon-DM {
  background-image: url(./sprite.png);
  background-position: -224px -266px;
  width: 32px;
  height: 32px;
}
.icon-DO {
  background-image: url(./sprite.png);
  background-position: -256px -266px;
  width: 32px;
  height: 32px;
}
.icon-DZ {
  background-image: url(./sprite.png);
  background-position: -304px 0px;
  width: 32px;
  height: 32px;
}
.icon-EC {
  background-image: url(./sprite.png);
  background-position: -304px -32px;
  width: 32px;
  height: 32px;
}
.icon-EE {
  background-image: url(./sprite.png);
  background-position: -304px -64px;
  width: 32px;
  height: 32px;
}
.icon-EG {
  background-image: url(./sprite.png);
  background-position: -304px -96px;
  width: 32px;
  height: 32px;
}
.icon-EH {
  background-image: url(./sprite.png);
  background-position: -304px -128px;
  width: 32px;
  height: 32px;
}
.icon-ER {
  background-image: url(./sprite.png);
  background-position: -304px -160px;
  width: 32px;
  height: 32px;
}
.icon-ES {
  background-image: url(./sprite.png);
  background-position: -304px -192px;
  width: 32px;
  height: 32px;
}
.icon-ET {
  background-image: url(./sprite.png);
  background-position: -304px -224px;
  width: 32px;
  height: 32px;
}
.icon-EU {
  background-image: url(./sprite.png);
  background-position: -304px -256px;
  width: 32px;
  height: 32px;
}
.icon-FI {
  background-image: url(./sprite.png);
  background-position: 0px -298px;
  width: 32px;
  height: 32px;
}
.icon-FJ {
  background-image: url(./sprite.png);
  background-position: -32px -298px;
  width: 32px;
  height: 32px;
}
.icon-FK {
  background-image: url(./sprite.png);
  background-position: -64px -298px;
  width: 32px;
  height: 32px;
}
.icon-FM {
  background-image: url(./sprite.png);
  background-position: -96px -298px;
  width: 32px;
  height: 32px;
}
.icon-FO {
  background-image: url(./sprite.png);
  background-position: -128px -298px;
  width: 32px;
  height: 32px;
}
.icon-FR {
  background-image: url(./sprite.png);
  background-position: -160px -298px;
  width: 32px;
  height: 32px;
}
.icon-GA {
  background-image: url(./sprite.png);
  background-position: -192px -298px;
  width: 32px;
  height: 32px;
}
.icon-GB {
  background-image: url(./sprite.png);
  background-position: -224px -298px;
  width: 32px;
  height: 32px;
}
.icon-GD {
  background-image: url(./sprite.png);
  background-position: -256px -298px;
  width: 32px;
  height: 32px;
}
.icon-GE {
  background-image: url(./sprite.png);
  background-position: -288px -298px;
  width: 32px;
  height: 32px;
}
.icon-GG {
  background-image: url(./sprite.png);
  background-position: -336px 0px;
  width: 32px;
  height: 32px;
}
.icon-GH {
  background-image: url(./sprite.png);
  background-position: -336px -32px;
  width: 32px;
  height: 32px;
}
.icon-GI {
  background-image: url(./sprite.png);
  background-position: -336px -64px;
  width: 32px;
  height: 32px;
}
.icon-GL {
  background-image: url(./sprite.png);
  background-position: -336px -96px;
  width: 32px;
  height: 32px;
}
.icon-GM {
  background-image: url(./sprite.png);
  background-position: -336px -128px;
  width: 32px;
  height: 32px;
}
.icon-GN {
  background-image: url(./sprite.png);
  background-position: -336px -160px;
  width: 32px;
  height: 32px;
}
.icon-GQ {
  background-image: url(./sprite.png);
  background-position: -336px -192px;
  width: 32px;
  height: 32px;
}
.icon-GR {
  background-image: url(./sprite.png);
  background-position: -336px -224px;
  width: 32px;
  height: 32px;
}
.icon-GS {
  background-image: url(./sprite.png);
  background-position: -336px -256px;
  width: 32px;
  height: 32px;
}
.icon-GT {
  background-image: url(./sprite.png);
  background-position: -336px -288px;
  width: 32px;
  height: 32px;
}
.icon-GU {
  background-image: url(./sprite.png);
  background-position: 0px -330px;
  width: 32px;
  height: 32px;
}
.icon-GW {
  background-image: url(./sprite.png);
  background-position: -32px -330px;
  width: 32px;
  height: 32px;
}
.icon-GY {
  background-image: url(./sprite.png);
  background-position: -64px -330px;
  width: 32px;
  height: 32px;
}
.icon-HK {
  background-image: url(./sprite.png);
  background-position: -96px -330px;
  width: 32px;
  height: 32px;
}
.icon-HN {
  background-image: url(./sprite.png);
  background-position: -128px -330px;
  width: 32px;
  height: 32px;
}
.icon-HR {
  background-image: url(./sprite.png);
  background-position: -160px -330px;
  width: 32px;
  height: 32px;
}
.icon-HT {
  background-image: url(./sprite.png);
  background-position: -192px -330px;
  width: 32px;
  height: 32px;
}
.icon-HU {
  background-image: url(./sprite.png);
  background-position: -224px -330px;
  width: 32px;
  height: 32px;
}
.icon-IC {
  background-image: url(./sprite.png);
  background-position: -256px -330px;
  width: 32px;
  height: 32px;
}
.icon-ID {
  background-image: url(./sprite.png);
  background-position: -288px -330px;
  width: 32px;
  height: 32px;
}
.icon-IE {
  background-image: url(./sprite.png);
  background-position: -320px -330px;
  width: 32px;
  height: 32px;
}
.icon-IL {
  background-image: url(./sprite.png);
  background-position: -368px 0px;
  width: 32px;
  height: 32px;
}
.icon-IM {
  background-image: url(./sprite.png);
  background-position: -368px -32px;
  width: 32px;
  height: 32px;
}
.icon-IN {
  background-image: url(./sprite.png);
  background-position: -368px -64px;
  width: 32px;
  height: 32px;
}
.icon-IQ {
  background-image: url(./sprite.png);
  background-position: -368px -96px;
  width: 32px;
  height: 32px;
}
.icon-IR {
  background-image: url(./sprite.png);
  background-position: -368px -128px;
  width: 32px;
  height: 32px;
}
.icon-IS {
  background-image: url(./sprite.png);
  background-position: -368px -160px;
  width: 32px;
  height: 32px;
}
.icon-IT {
  background-image: url(./sprite.png);
  background-position: -368px -192px;
  width: 32px;
  height: 32px;
}
.icon-JE {
  background-image: url(./sprite.png);
  background-position: -368px -224px;
  width: 32px;
  height: 32px;
}
.icon-JM {
  background-image: url(./sprite.png);
  background-position: -368px -256px;
  width: 32px;
  height: 32px;
}
.icon-JO {
  background-image: url(./sprite.png);
  background-position: -368px -288px;
  width: 32px;
  height: 32px;
}
.icon-JP {
  background-image: url(./sprite.png);
  background-position: -368px -320px;
  width: 32px;
  height: 32px;
}
.icon-KE {
  background-image: url(./sprite.png);
  background-position: 0px -362px;
  width: 32px;
  height: 32px;
}
.icon-KG {
  background-image: url(./sprite.png);
  background-position: -32px -362px;
  width: 32px;
  height: 32px;
}
.icon-KH {
  background-image: url(./sprite.png);
  background-position: -64px -362px;
  width: 32px;
  height: 32px;
}
.icon-KI {
  background-image: url(./sprite.png);
  background-position: -96px -362px;
  width: 32px;
  height: 32px;
}
.icon-KM {
  background-image: url(./sprite.png);
  background-position: -128px -362px;
  width: 32px;
  height: 32px;
}
.icon-KN {
  background-image: url(./sprite.png);
  background-position: -160px -362px;
  width: 32px;
  height: 32px;
}
.icon-KP {
  background-image: url(./sprite.png);
  background-position: -192px -362px;
  width: 32px;
  height: 32px;
}
.icon-KR {
  background-image: url(./sprite.png);
  background-position: -224px -362px;
  width: 32px;
  height: 32px;
}
.icon-KW {
  background-image: url(./sprite.png);
  background-position: -256px -362px;
  width: 32px;
  height: 32px;
}
.icon-KY {
  background-image: url(./sprite.png);
  background-position: -288px -362px;
  width: 32px;
  height: 32px;
}
.icon-KZ {
  background-image: url(./sprite.png);
  background-position: -320px -362px;
  width: 32px;
  height: 32px;
}
.icon-LA {
  background-image: url(./sprite.png);
  background-position: -352px -362px;
  width: 32px;
  height: 32px;
}
.icon-LB {
  background-image: url(./sprite.png);
  background-position: -400px 0px;
  width: 32px;
  height: 32px;
}
.icon-LC {
  background-image: url(./sprite.png);
  background-position: -400px -32px;
  width: 32px;
  height: 32px;
}
.icon-LI {
  background-image: url(./sprite.png);
  background-position: -400px -64px;
  width: 32px;
  height: 32px;
}
.icon-LK {
  background-image: url(./sprite.png);
  background-position: -400px -96px;
  width: 32px;
  height: 32px;
}
.icon-locked-xs {
  background-image: url(./sprite.png);
  background-position: -528px -224px;
  width: 20px;
  height: 21px;
}
.icon-locked {
  background-image: url(./sprite.png);
  background-position: 0px 0px;
  width: 40px;
  height: 42px;
}
.icon-LR {
  background-image: url(./sprite.png);
  background-position: -400px -128px;
  width: 32px;
  height: 32px;
}
.icon-LS {
  background-image: url(./sprite.png);
  background-position: -400px -160px;
  width: 32px;
  height: 32px;
}
.icon-LT {
  background-image: url(./sprite.png);
  background-position: -400px -192px;
  width: 32px;
  height: 32px;
}
.icon-LU {
  background-image: url(./sprite.png);
  background-position: -400px -224px;
  width: 32px;
  height: 32px;
}
.icon-LV {
  background-image: url(./sprite.png);
  background-position: -400px -256px;
  width: 32px;
  height: 32px;
}
.icon-LY {
  background-image: url(./sprite.png);
  background-position: -400px -288px;
  width: 32px;
  height: 32px;
}
.icon-MA {
  background-image: url(./sprite.png);
  background-position: -400px -320px;
  width: 32px;
  height: 32px;
}
.icon-MC {
  background-image: url(./sprite.png);
  background-position: -400px -352px;
  width: 32px;
  height: 32px;
}
.icon-MD {
  background-image: url(./sprite.png);
  background-position: 0px -394px;
  width: 32px;
  height: 32px;
}
.icon-ME {
  background-image: url(./sprite.png);
  background-position: -32px -394px;
  width: 32px;
  height: 32px;
}
.icon-MF {
  background-image: url(./sprite.png);
  background-position: -64px -394px;
  width: 32px;
  height: 32px;
}
.icon-MG {
  background-image: url(./sprite.png);
  background-position: -96px -394px;
  width: 32px;
  height: 32px;
}
.icon-MH {
  background-image: url(./sprite.png);
  background-position: -128px -394px;
  width: 32px;
  height: 32px;
}
.icon-MK {
  background-image: url(./sprite.png);
  background-position: -160px -394px;
  width: 32px;
  height: 32px;
}
.icon-ML {
  background-image: url(./sprite.png);
  background-position: -192px -394px;
  width: 32px;
  height: 32px;
}
.icon-MM {
  background-image: url(./sprite.png);
  background-position: -224px -394px;
  width: 32px;
  height: 32px;
}
.icon-MN {
  background-image: url(./sprite.png);
  background-position: -256px -394px;
  width: 32px;
  height: 32px;
}
.icon-MO {
  background-image: url(./sprite.png);
  background-position: -288px -394px;
  width: 32px;
  height: 32px;
}
.icon-MP {
  background-image: url(./sprite.png);
  background-position: -320px -394px;
  width: 32px;
  height: 32px;
}
.icon-MQ {
  background-image: url(./sprite.png);
  background-position: -352px -394px;
  width: 32px;
  height: 32px;
}
.icon-MR {
  background-image: url(./sprite.png);
  background-position: -384px -394px;
  width: 32px;
  height: 32px;
}
.icon-MS {
  background-image: url(./sprite.png);
  background-position: -432px 0px;
  width: 32px;
  height: 32px;
}
.icon-MT {
  background-image: url(./sprite.png);
  background-position: -432px -32px;
  width: 32px;
  height: 32px;
}
.icon-MU {
  background-image: url(./sprite.png);
  background-position: -432px -64px;
  width: 32px;
  height: 32px;
}
.icon-MV {
  background-image: url(./sprite.png);
  background-position: -432px -96px;
  width: 32px;
  height: 32px;
}
.icon-MW {
  background-image: url(./sprite.png);
  background-position: -432px -128px;
  width: 32px;
  height: 32px;
}
.icon-MX {
  background-image: url(./sprite.png);
  background-position: -432px -160px;
  width: 32px;
  height: 32px;
}
.icon-MY {
  background-image: url(./sprite.png);
  background-position: -432px -192px;
  width: 32px;
  height: 32px;
}
.icon-MZ {
  background-image: url(./sprite.png);
  background-position: -432px -224px;
  width: 32px;
  height: 32px;
}
.icon-NA {
  background-image: url(./sprite.png);
  background-position: -432px -256px;
  width: 32px;
  height: 32px;
}
.icon-NC {
  background-image: url(./sprite.png);
  background-position: -432px -288px;
  width: 32px;
  height: 32px;
}
.icon-NE {
  background-image: url(./sprite.png);
  background-position: -432px -320px;
  width: 32px;
  height: 32px;
}
.icon-NF {
  background-image: url(./sprite.png);
  background-position: -432px -352px;
  width: 32px;
  height: 32px;
}
.icon-NG {
  background-image: url(./sprite.png);
  background-position: -432px -384px;
  width: 32px;
  height: 32px;
}
.icon-NI {
  background-image: url(./sprite.png);
  background-position: 0px -426px;
  width: 32px;
  height: 32px;
}
.icon-NL {
  background-image: url(./sprite.png);
  background-position: -32px -426px;
  width: 32px;
  height: 32px;
}
.icon-NO {
  background-image: url(./sprite.png);
  background-position: -64px -426px;
  width: 32px;
  height: 32px;
}
.icon-NP {
  background-image: url(./sprite.png);
  background-position: -96px -426px;
  width: 32px;
  height: 32px;
}
.icon-NR {
  background-image: url(./sprite.png);
  background-position: -128px -426px;
  width: 32px;
  height: 32px;
}
.icon-NU {
  background-image: url(./sprite.png);
  background-position: -160px -426px;
  width: 32px;
  height: 32px;
}
.icon-NZ {
  background-image: url(./sprite.png);
  background-position: -192px -426px;
  width: 32px;
  height: 32px;
}
.icon-OM {
  background-image: url(./sprite.png);
  background-position: -224px -426px;
  width: 32px;
  height: 32px;
}
.icon-PA {
  background-image: url(./sprite.png);
  background-position: -256px -426px;
  width: 32px;
  height: 32px;
}
.icon-PE {
  background-image: url(./sprite.png);
  background-position: -288px -426px;
  width: 32px;
  height: 32px;
}
.icon-PF {
  background-image: url(./sprite.png);
  background-position: -320px -426px;
  width: 32px;
  height: 32px;
}
.icon-PG {
  background-image: url(./sprite.png);
  background-position: -352px -426px;
  width: 32px;
  height: 32px;
}
.icon-PH {
  background-image: url(./sprite.png);
  background-position: -384px -426px;
  width: 32px;
  height: 32px;
}
.icon-PK {
  background-image: url(./sprite.png);
  background-position: -416px -426px;
  width: 32px;
  height: 32px;
}
.icon-PL {
  background-image: url(./sprite.png);
  background-position: -464px 0px;
  width: 32px;
  height: 32px;
}
.icon-PN {
  background-image: url(./sprite.png);
  background-position: -464px -32px;
  width: 32px;
  height: 32px;
}
.icon-PR {
  background-image: url(./sprite.png);
  background-position: -464px -64px;
  width: 32px;
  height: 32px;
}
.icon-PS {
  background-image: url(./sprite.png);
  background-position: -464px -96px;
  width: 32px;
  height: 32px;
}
.icon-PT {
  background-image: url(./sprite.png);
  background-position: -464px -128px;
  width: 32px;
  height: 32px;
}
.icon-PW {
  background-image: url(./sprite.png);
  background-position: -464px -160px;
  width: 32px;
  height: 32px;
}
.icon-PY {
  background-image: url(./sprite.png);
  background-position: -464px -192px;
  width: 32px;
  height: 32px;
}
.icon-QA {
  background-image: url(./sprite.png);
  background-position: -464px -224px;
  width: 32px;
  height: 32px;
}
.icon-RO {
  background-image: url(./sprite.png);
  background-position: -464px -256px;
  width: 32px;
  height: 32px;
}
.icon-RS {
  background-image: url(./sprite.png);
  background-position: -464px -288px;
  width: 32px;
  height: 32px;
}
.icon-RU {
  background-image: url(./sprite.png);
  background-position: -464px -320px;
  width: 32px;
  height: 32px;
}
.icon-RW {
  background-image: url(./sprite.png);
  background-position: -464px -352px;
  width: 32px;
  height: 32px;
}
.icon-SA {
  background-image: url(./sprite.png);
  background-position: -464px -384px;
  width: 32px;
  height: 32px;
}
.icon-SB {
  background-image: url(./sprite.png);
  background-position: -464px -416px;
  width: 32px;
  height: 32px;
}
.icon-SC {
  background-image: url(./sprite.png);
  background-position: 0px -458px;
  width: 32px;
  height: 32px;
}
.icon-SD {
  background-image: url(./sprite.png);
  background-position: -32px -458px;
  width: 32px;
  height: 32px;
}
.icon-SE {
  background-image: url(./sprite.png);
  background-position: -64px -458px;
  width: 32px;
  height: 32px;
}
.icon-SG {
  background-image: url(./sprite.png);
  background-position: -96px -458px;
  width: 32px;
  height: 32px;
}
.icon-SH {
  background-image: url(./sprite.png);
  background-position: -128px -458px;
  width: 32px;
  height: 32px;
}
.icon-SI {
  background-image: url(./sprite.png);
  background-position: -160px -458px;
  width: 32px;
  height: 32px;
}
.icon-SK {
  background-image: url(./sprite.png);
  background-position: -192px -458px;
  width: 32px;
  height: 32px;
}
.icon-SL {
  background-image: url(./sprite.png);
  background-position: -224px -458px;
  width: 32px;
  height: 32px;
}
.icon-SM {
  background-image: url(./sprite.png);
  background-position: -256px -458px;
  width: 32px;
  height: 32px;
}
.icon-SN {
  background-image: url(./sprite.png);
  background-position: -288px -458px;
  width: 32px;
  height: 32px;
}
.icon-SO {
  background-image: url(./sprite.png);
  background-position: -320px -458px;
  width: 32px;
  height: 32px;
}
.icon-SR {
  background-image: url(./sprite.png);
  background-position: -352px -458px;
  width: 32px;
  height: 32px;
}
.icon-SS {
  background-image: url(./sprite.png);
  background-position: -384px -458px;
  width: 32px;
  height: 32px;
}
.icon-ST {
  background-image: url(./sprite.png);
  background-position: -416px -458px;
  width: 32px;
  height: 32px;
}
.icon-SV {
  background-image: url(./sprite.png);
  background-position: -448px -458px;
  width: 32px;
  height: 32px;
}
.icon-SY {
  background-image: url(./sprite.png);
  background-position: -496px 0px;
  width: 32px;
  height: 32px;
}
.icon-SZ {
  background-image: url(./sprite.png);
  background-position: -496px -32px;
  width: 32px;
  height: 32px;
}
.icon-TC {
  background-image: url(./sprite.png);
  background-position: -496px -64px;
  width: 32px;
  height: 32px;
}
.icon-TD {
  background-image: url(./sprite.png);
  background-position: -496px -96px;
  width: 32px;
  height: 32px;
}
.icon-TF {
  background-image: url(./sprite.png);
  background-position: -496px -128px;
  width: 32px;
  height: 32px;
}
.icon-TG {
  background-image: url(./sprite.png);
  background-position: -496px -160px;
  width: 32px;
  height: 32px;
}
.icon-TH {
  background-image: url(./sprite.png);
  background-position: -496px -192px;
  width: 32px;
  height: 32px;
}
.icon-TJ {
  background-image: url(./sprite.png);
  background-position: -496px -224px;
  width: 32px;
  height: 32px;
}
.icon-TK {
  background-image: url(./sprite.png);
  background-position: -496px -256px;
  width: 32px;
  height: 32px;
}
.icon-TL {
  background-image: url(./sprite.png);
  background-position: -496px -288px;
  width: 32px;
  height: 32px;
}
.icon-TM {
  background-image: url(./sprite.png);
  background-position: -496px -320px;
  width: 32px;
  height: 32px;
}
.icon-TN {
  background-image: url(./sprite.png);
  background-position: -496px -352px;
  width: 32px;
  height: 32px;
}
.icon-TO {
  background-image: url(./sprite.png);
  background-position: -496px -384px;
  width: 32px;
  height: 32px;
}
.icon-TR {
  background-image: url(./sprite.png);
  background-position: -496px -416px;
  width: 32px;
  height: 32px;
}
.icon-TT {
  background-image: url(./sprite.png);
  background-position: -496px -448px;
  width: 32px;
  height: 32px;
}
.icon-TV {
  background-image: url(./sprite.png);
  background-position: 0px -490px;
  width: 32px;
  height: 32px;
}
.icon-TW {
  background-image: url(./sprite.png);
  background-position: -32px -490px;
  width: 32px;
  height: 32px;
}
.icon-TZ {
  background-image: url(./sprite.png);
  background-position: -64px -490px;
  width: 32px;
  height: 32px;
}
.icon-UA {
  background-image: url(./sprite.png);
  background-position: -96px -490px;
  width: 32px;
  height: 32px;
}
.icon-UG {
  background-image: url(./sprite.png);
  background-position: -128px -490px;
  width: 32px;
  height: 32px;
}
.icon-UK {
  background-image: url(./sprite.png);
  background-position: -160px -490px;
  width: 32px;
  height: 32px;
}
.icon-unlocked-xs {
  background-image: url(./sprite.png);
  background-position: -528px -245px;
  width: 20px;
  height: 21px;
}
.icon-unlocked {
  background-image: url(./sprite.png);
  background-position: -40px 0px;
  width: 40px;
  height: 42px;
}
.icon-US {
  background-image: url(./sprite.png);
  background-position: -192px -490px;
  width: 32px;
  height: 32px;
}
.icon-UY {
  background-image: url(./sprite.png);
  background-position: -224px -490px;
  width: 32px;
  height: 32px;
}
.icon-UZ {
  background-image: url(./sprite.png);
  background-position: -256px -490px;
  width: 32px;
  height: 32px;
}
.icon-VA {
  background-image: url(./sprite.png);
  background-position: -288px -490px;
  width: 32px;
  height: 32px;
}
.icon-VC {
  background-image: url(./sprite.png);
  background-position: -320px -490px;
  width: 32px;
  height: 32px;
}
.icon-VE {
  background-image: url(./sprite.png);
  background-position: -352px -490px;
  width: 32px;
  height: 32px;
}
.icon-VG {
  background-image: url(./sprite.png);
  background-position: -384px -490px;
  width: 32px;
  height: 32px;
}
.icon-VI {
  background-image: url(./sprite.png);
  background-position: -416px -490px;
  width: 32px;
  height: 32px;
}
.icon-VN {
  background-image: url(./sprite.png);
  background-position: -448px -490px;
  width: 32px;
  height: 32px;
}
.icon-VU {
  background-image: url(./sprite.png);
  background-position: -480px -490px;
  width: 32px;
  height: 32px;
}
.icon-WF {
  background-image: url(./sprite.png);
  background-position: -528px 0px;
  width: 32px;
  height: 32px;
}
.icon-WS {
  background-image: url(./sprite.png);
  background-position: -528px -32px;
  width: 32px;
  height: 32px;
}
.icon-YE {
  background-image: url(./sprite.png);
  background-position: -528px -64px;
  width: 32px;
  height: 32px;
}
.icon-YT {
  background-image: url(./sprite.png);
  background-position: -528px -96px;
  width: 32px;
  height: 32px;
}
.icon-ZA {
  background-image: url(./sprite.png);
  background-position: -528px -128px;
  width: 32px;
  height: 32px;
}
.icon-ZM {
  background-image: url(./sprite.png);
  background-position: -528px -160px;
  width: 32px;
  height: 32px;
}
.icon-ZW {
  background-image: url(./sprite.png);
  background-position: -528px -192px;
  width: 32px;
  height: 32px;
}
