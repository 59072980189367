@font-face {
  font-family: 'FAP';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url('@fortawesome/fontawesome-pro/webfonts/fa-light-300.woff2');
  src:
    url('@fortawesome/fontawesome-pro/webfonts/fa-light-300.woff2') format('woff2'),
    url('@fortawesome/fontawesome-pro/webfonts/fa-light-300.ttf') format('truetype');
}

.fal {
  font-family: 'FAP';
  font-weight: 300;
}
@font-face {
  font-family: 'FAP';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('@fortawesome/fontawesome-pro/webfonts/fa-regular-400.woff2');
  src:
    url('@fortawesome/fontawesome-pro/webfonts/fa-regular-400.woff2') format('woff2'),
    url('@fortawesome/fontawesome-pro/webfonts/fa-regular-400.ttf') format('truetype');
}

.far {
  font-family: 'FAP';
  font-weight: 400;
}
@font-face {
  font-family: 'FAP';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url('@fortawesome/fontawesome-pro/webfonts/fa-solid-900.woff2');
  src:
    url('@fortawesome/fontawesome-pro/webfonts/fa-solid-900.woff2') format('woff2'),
    url('@fortawesome/fontawesome-pro/webfonts/fa-solid-900.ttf') format('truetype');
}

.fa,
.fas {
  font-family: 'FAP';
  font-weight: 900;
}
